<template>
  <div>
    <label class="label is-size-7">Sort by</label>
    <div class="control">
      <multi-select
        v-model="sortBy"
        :options="options"
        :show-labels="false"
        :close-on-select="true"
        :allow-empty="false"
        :group-select="false"
        track-by="label"
        label="label"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimilarListingsSort',
  props: {
    location: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    'multi-select': () => import('vue-multiselect')
  },
  computed: {
    options() {
      return [
        { type: 'daysOnSale', order: 'desc', label: 'Newest' },
        { type: 'daysOnSale', order: 'asc', label: 'Oldest' },
        { type: '', order: '', label: 'Most relevant' },
        { type: 'year', order: 'desc', label: 'Youngest vehicle' },
        { type: 'year', order: 'asc', label: 'Oldest vehicle' },
        { type: 'price', order: 'desc', label: 'Most expensive' },
        { type: 'price', order: 'asc', label: 'Least expensive' },
        { type: 'mileage', order: 'desc', label: 'Highest mileage' },
        { type: 'mileage', order: 'asc', label: 'Lowest mileage' },
        { type: 'colour', order: 'asc', label: 'Colour (A-Z)' },
        { type: 'colour', order: 'desc', label: 'Colour (Z-A)' },
        {
          type: 'distance',
          order: 'asc',
          label: 'Closest to you',
          location: true,
          $isDisabled: !this.location
        },
        {
          type: 'distance',
          order: 'desc',
          label: 'Furthest from you',
          location: true,
          $isDisabled: !this.location
        }
      ]
    },
    sortBy: {
      get() {
        return this.$store.state.companion.similarListings.sortBy
      },
      set(sortBy) {
        this.$mxp.track(
          `sort_similar_listings_by_${sortBy.type}_${sortBy.order}`
        )
        this.$store.dispatch('companion/similarListings/sortAndFetch', {
          sortBy
        })
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass">
.multiselect
  min-width: 16rem
  max-width: 100%
  .multiselect__option--selected
    background: #ccc
    color: #fff
    &.multiselect__option--highlight
      background: #ccc
      cursor: not-allowed
.multiselect__option
  &.multiselect__option--highlight
    background: $primary
</style>
